// Variables
@import './utilities/variables';

.video_player {
    padding-bottom: 28.125%;
    width: 50%;
    left: 25%;
    position: relative;
    margin: 50px 0;

    @include phone {
        width: 100%;
        left: 0;
        padding-bottom: 56.25%;
    }

    // background: red;
    .cover {
        // display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, visibility 0s 0.5s;
        cursor: pointer;

        &.visible {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.5s ease, visibility 0s;
        }
    }

    .video {
        position: absolute;
    }
}