// Variables
@import './utilities/variables';

.language_chooser {
	.icon_language {
		width: 40px;
		height: 40px;
		margin: 0 auto;
	}
	.heading {
		text-align: center;
		// display: block;
		// color: red;
		margin: 10px 0;
	}
	.region {
		border: none;
		background: red;
		padding-bottom: 40%;
		width: 50%;
		position: relative;
		border-radius: 0;
		float: left;
		&:hover {
			border: none;
		}
		button {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&.switzerland {
			background: url('../img/vector/europe.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.canada {
			background: url('../img/vector/canada.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
	.language_holder {
		display: flex;
		width: 80%;
		margin: 10px auto;
		justify-content: space-between;
		button.language {
			background: none;
			padding: 3px;
			border: 2px transparent solid;
			width: 50px;
			height: 50px;

			// padding-bottom: 15%;
			position: relative;
			float: left;

			border-radius: 50%;
			transition: border 0.5s ease;
			&:hover {
				background: none;
				border: 2px $green solid;
			}
			&.active {
				border: 2px $green solid;
			}
			.icon {
				background-position: center;
				// position: absolute;
				// border: 3px red solid;
				border-radius: 50%;
				background-size: 105%;
				height: 100%;
				width: 100%;
			}
		}
	}
}
