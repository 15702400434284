@import './utilities/variables';

.single_homeopathy_display_wrapper {
  // background: red;
  position: relative;
  width: 70%;
  max-width: 1200px;
  margin: 50px auto;
  @include phone {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    margin: 0;
  }

  .icon_background {
    width: 40%;
    padding-bottom: 30%;
    position: absolute;
    opacity: 0.1;
    left: -7%;
    top: -94px;
    z-index: -1;
    @include phone {
      width: 80%;
      left: -10%;
      padding-bottom: 50%;
    }
  }

  .product_display {
    width: 25%;
    float: left;
    padding-bottom: 30%;
    // background: blue;
  }

  .product_preview {
    @include phone {
      display: none;
    }
  }

  .remedy_display {
    width: 70%;
    float: left;
    margin-left: 5%;

    @include phone {
      width: 100%;
      margin-left: 0;
      float: none;
    }

    .section {
      padding-left: 100px;
      position: relative;
      min-height: 30px;
      margin: 20px 0;
      @include phone {
        padding: 0;
      }
    }

    .label {
      font-size: $small_font;
      position: absolute;
      left: 0;
      top: 16px;
      color: $grey;
      max-width: 90px;
      @include phone {
        position: relative;
        top: 0;
        max-width: none;
      }
    }

    .form .button_group,
    .scale .button_group {
      display: flex;
      justify-content: space-between;
      // align-content: strech;
      &.sort_left {
        justify-content: left;
      }
      flex-wrap: wrap;
      @include no-desktop {
        justify-content: space-between;
      }
      button {
        font-size: $small_font;
        white-space: pre-line;
      }

      .icon_holder {
        left: 50%;
        width: 100%;
        height: 35px;
        margin-bottom: 10px;
        position: relative;
        transform: translate(-50%, 0);

        .icon {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }

    .show_all {
      float: right;
    }

    .dilution_slider_holder {
      user-select: none;
      position: relative;
      top: 6px;
      @include no-desktop {
        top: 16px;
      }

      .min {
        position: absolute;
        left: 0;
        font-size: $small_font;
        color: $grey;
        top: 10px;
        user-select: none;
        &.only {
          color: $primary;
          font-weight: 700;
        }
        @include no-desktop {
          top: -10px;
        }
      }

      .max {
        position: absolute;
        right: 0;
        font-size: $small_font;
        color: $grey;
        top: 10px;
        user-select: none;
        @include no-desktop {
          top: -10px;
        }
      }

      .dilution_slider {
        position: relative;
        width: 80%;
        left: 10%;
        height: 80px;
        @include no-desktop {
          width: 100%;
          left: 0%;
        }

        span {
          font-size: $small_font;
          color: $grey;
          top: 10px;
          user-select: none;
        }

        .slider_line {
          position: absolute;
          width: 100%;
          height: 1px;
          background: $grey;
          // left: 10%;
          top: 18px;
        }

        .slider_dot {
          touch-action: none;
          width: 15px;
          height: 15px;
          background: $green;
          position: absolute;
          top: 10px;
          // left: 50%;
          margin-left: -7px;
          border-radius: 50%;
          box-shadow: $box_shadow;
          cursor: grab;

          // transition: left 0.1s ease;
          .current_value {
            position: absolute;
            text-align: center;
            top: 20px;
            left: 50%;
            transform: translate(-50%, 0);
            color: $green;
          }

          .current_value_mobile {
            display: block;
            @include desktop {
              display: none;
            }
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            background: $primary;
            padding: 10px;
            border-radius: 5px;
            font-weight: 700;
            color: $white;
            opacity: 0;
            transition: opacity 0.5s ease;
            &.touch {
              opacity: 1;
            }
          }

          .slider_handle_overlay {
            width: 60px;
            height: 60px;
            position: absolute;
            opacity: 0.5;
            top: -25px;
            left: -25px;
            // background: red;
            // transform: translate(-50%, -50%);
          }
        }
      }
    }

    .remedies {
      position: relative;
      width: 100%;

      // left: 20%;
      // margin-top: 30px;
      button.remedy {
        padding: 5px 10px;
        transition: background 0s ease;
        cursor: pointer;
        display: block;
        border: none;
        color: $grey;
        width: 100%;
        border-radius: 0;
        text-align: left;

        &:hover {
          background: $bg_grey;
          text-decoration: underline;
          color: $green;
        }

        &:last-child {
          margin-bottom: 50px;
        }
      }
    }
  }

  .add_to_cart {
    @include no-desktop {
      text-align: center;
      h1 {
        text-align: center;
      }
    }

    .product_picture {
      width: 20%;
      padding-bottom: 30%; // background: url('../img/tube.png') no-repeat center/contain;
      float: left;
      display: block;
      @include no-desktop {
        width: 50%;
        float: none;
        margin: 10px auto;
      }
    }

    .product_info {
      float: left;
      margin-left: 10px;
      @include no-desktop {
        margin: 0 auto;
        text-align: center;
        width: 100%;
      }

      h2 {
        margin-top: 0;
        width: 80%;
        margin: 10px 0;
        @include no-desktop {
          margin: 10px auto;
        }
      }

      p {
        margin: 0;
        line-height: 150%;

        &.substances {
          margin: 10px 0 0 0;
        }
      }

      .price {
        color: $green;
        display: block;
        margin: 10px 0;
        @include no-desktop {
          margin-top: 30px;
        }
      }

      .buy {
        margin-left: 20px;
        display: block;
        margin: 10px 0;
        @include no-desktop {
          margin: 10px auto 0 auto;
        }
      }

      .quantity_field {
        // margin: 0;
        margin: 10px 0;
        display: block;
        float: none;
        @include no-desktop {
          margin: 10px auto 0 auto;
        }
        // width: 100%;
      }
    }
  }

  .loading {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s ease;
  }

  &.loading {
    .loading {
      display: block;
      visibility: visible;
      opacity: 1;
      background: $trans_white;
      transition: visibility 0s 0s, opacity 0.5s ease;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      span {
        position: absolute;
        color: $grey;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
