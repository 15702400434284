// Variables
@import './utilities/variables';

.otc_holder {
    margin-bottom: 50px;
    margin-top: 50px;
    max-width: $max-width;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .image {
        position: relative;
        width: 100%;
        // padding-bottom: 20%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.1);
        }

        h1 {
            color: $white;
            font-weight: 500;
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .text_holder {
        width: 50%;
        max-width: $max-width;
        margin: 120px auto 0 auto;
    }

    // .box {
    //  position: absolute;
    //  width: 60%;
    //  text-align: center;
    //  left: 50%;

    justify-content: center;
    //  transform: translate3d(-50%, -50%, 0);
}

// ---------------- ALSO MAKE THIS ACCESSIBLE TO COMPLEXES SLIDER ---------------- //
.single_otc {
    padding: 40px 20% 15% 20px;
    // float: left;
    position: relative;
    box-sizing: border-box;
    display: block;
    // box-shadow: $box_shadow;
    width: 30%;
    background: $white;
    margin: 1.5% 1.5%;
    text-align: left;
    overflow: hidden;
    cursor: pointer;
    @include phone {
        width: 100%;
        padding-bottom: 45%;
        margin: 20px 1.5%;
    }

    .text {
        // margin: 30px 0 0;
        width: 100%;
        position: absolute;
        top: 85%;
        left: 0px;

        text-align: center;

        // background: red;
        p {
            // z-index: 1;
            line-height: 120%;
            margin: 5px 0;
            font-size: $small_font;
        }

        .title {
            color: $green;
            text-decoration: none;
            font-weight: 500;
            font-size: $font_size;
        }
    }

    .no {
        position: absolute;
        left: 6px;
        top: -37px;
        margin: 0;
        font-size: 100px;
        color: $bg_grey;
        z-index: 0;
        opacity: 0.7;
        transition: opacity 0.5s ease, color 0.5s ease;
    }

    .image {
        // background: blue;#

        // z-index: 0;
        position: absolute;
        top: 10px;
        left: 10%;
        width: 80%;
        height: 50%;
        transform: translate3d(0, 0, 0);
        background: transparent url('../img/otc_generic.jpg') no-repeat
            top/contain;
        transition: transform 0.5s ease-in-out;
        @include phone {
            height: 70%;
        }
    }

    &:hover {
        .title {
            text-decoration: underline;
        }

        .image {
            transform: translate3d(0, -10px, 0);
        }

        // }
    }

    .icon {
        margin: 0 auto;
        width: 70px;
        height: 70px;
    }
}
