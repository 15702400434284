@import './utilities/variables';

.make_your_own_display_wrapper {
  position: relative;
  width: 75%;
  max-width: 1200px;
  margin: 50px auto;
  min-height: 300px;
  @include phone {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    margin: 0;
  }

  .product_display {
    width: 15%;
    float: left;
    padding-bottom: 30%;
    margin-left: 12.5%;
    // background: blue;
    background: url('../img/tube.png') no-repeat center/contain;
    @include phone {
      display: none;
    }
  }

  .configurator {
    width: 55%;
    // background: $bg_grey;
    float: left;
    margin: 0 0 0 5%;
    min-height: 50px;
    @include phone {
      width: 100%;
      margin: 0;
    }
  }

  .saved_remedies {
    // width: 20%;
    // background: $bg_grey;
    float: left;
    margin: 0 0 0 5%;
    min-height: 50px;
  }

  .form_selector {
    width: 60%;
    left: 20%;
    position: relative;
    display: flex;
    justify-content: space-between;

    button {
      // width: 20%;
      position: relative;

      span {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate3d(-50%, -50%);
      }
    }
  }

  .single_substance {
    background: white;
    padding: 10px;
    margin: 15px 0;
    min-height: 28px;
    box-shadow: $box-shadow;
    position: relative;

    .substance_controls {
      max-height: 600px;
      overflow: hidden;
      transition: max-height 0.5s ease;
    }

    &.closed .substance_controls {
      //display: none;
      max-height: 0px;
    }

    .substance_summary {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      display: block;
      color: $green;
      padding: 5px 0;
      margin: 0;
      border: 0;
      background: none;
      //max-height: 0px;
      transition: opacity 0.5s ease, visibility 0s 0.5s;

      &:hover,
      &:active {
        background: none;
      }
    }

    &.closed .substance_summary {
      opacity: 1;
      visibility: visible;
      //max-height: 100px;
      transition: opacity 0.5s 0.5s ease, visibility 0s 0.5s;
    }

    .remedies {
      position: relative;
      width: 100%;

      // left: 20%;
      // margin-top: 30px;
      button.remedy {
        padding: 5px 10px;
        transition: background 0s ease;
        cursor: pointer;
        display: block;
        border: none;
        color: $grey;
        width: 100%;
        border-radius: 0;
        text-align: left;

        &:hover {
          background: $bg_grey;
          text-decoration: underline;
          color: $green;
        }

        &:first-child {
          // margin-top: 30px;
        }

        &:last-child {
          margin-bottom: 50px;
        }
      }
    }

    .dilution_slider_holder {
      position: relative;
      top: 6px;
      width: 100%;
      @include no-desktop {
        top: 16px;
      }

      .min {
        position: absolute;
        left: 0;
        font-size: $small_font;
        color: $grey;
        top: 10px;
        user-select: none;
        @include no-desktop {
          top: -10px;
        }
      }

      .max {
        position: absolute;
        right: 0;
        font-size: $small_font;
        color: $grey;
        top: 10px;
        user-select: none;
        @include no-desktop {
          top: -10px;
        }
      }

      .dilution_slider {
        position: relative;
        width: 80%;
        left: 10%;
        height: 80px;
        @include no-desktop {
          // width: 100%;
          // left: 10%;
        }

        span {
          font-size: $small_font;
          color: $grey;
          top: 10px;
          user-select: none;
        }

        .slider_line {
          position: absolute;
          width: 100%;
          height: 1px;
          background: $grey;
          // left: 10%;
          top: 18px;
        }

        .slider_dot {
          touch-action: none;
          width: 15px;
          height: 15px;
          background: $green;
          position: absolute;
          top: 10px;
          // left: 50%;
          margin-left: -7px;
          border-radius: 50%;
          box-shadow: $box_shadow;
          cursor: grab;

          // transition: left 0.1s ease;
          .current_value {
            position: absolute;
            text-align: center;
            top: 20px;
            left: 50%;
            transform: translate(-50%, 0);
            color: $green;
          }

          .current_value_mobile {
            display: block;
            @include desktop {
              display: none;
            }
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            background: $primary;
            padding: 10px;
            border-radius: 5px;
            font-weight: 700;
            color: $white;
            opacity: 0;
            transition: opacity 0.5s ease;
            &.touch {
              opacity: 1;
            }
          }

          .slider_handle_overlay {
            width: 60px;
            height: 60px;
            position: absolute;
            opacity: 0.5;
            top: -25px;
            left: -25px;
            // background: red;
            // transform: translate(-50%, -50%);
          }
        }
      }
    }

    .close {
      float: right;
      margin: 5px;
    }

    .delete {
      margin: 5px;
      float: right;
    }

    .delete_dialog {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $trans_white;
      transition: opacity 0.5s ease, visibility 0s 0.5s;

      &.visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s ease, visibility 0s 0s;
      }

      .dialog_holder {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        p {
          margin: 0;
          text-align: center;
        }

        button {
          // text-align: center;
          margin: 10px 5px;
        }
      }
    }
  }

  .label {
    font-size: 12px;
    color: #868686;
  }

  .add_to_cart {
    .product_picture {
      width: 20%;
      padding-bottom: 30%;
      // background: url('../img/tube.png') no-repeat center/contain;
      float: left;
    }

    .product_info {
      float: left;
      margin-left: 5%;
      width: 75%;

      & > *:first-child {
        margin-top: 0;
      }

      .debug_info {
        p {
          margin: 20px 0;
        }
      }

      p {
        margin: 0;
        line-height: 150%;

        &.substances {
          margin: 10px 0 0 0;
        }
      }

      .price {
        color: $green;
        display: block;
        margin-top: 10px;
      }

      .quantity_field {
        // margin-left: 20px;
        margin: 10px 0 0 0;
      }

      .buy {
        // margin-left: 20px;
        margin: 10px 0 0 0;
      }
    }
  }
}

.make_your_own_display_wrapper,
.single_homeopathy_display_wrapper {
  .scale button {
    margin: 10px 10px 10px 0;
  }

  .section {
    @include no-desktop {
      margin-bottom: 15px;
    }
  }

  .form button,
  .carrier button,
  .size button,
  .type.section button {
    min-width: 70px;
    background: none;
    border: none;
    color: $grey;
    padding: 10px 15px;
    font-size: 12px;

    &:nth-child(2) {
      // padding-left: 0;
    }

    .icon {
      opacity: 1;
      transition: opacity 0.5s ease;

      &.active {
        opacity: 0;
      }
    }

    &:hover:not(.disabled),
    &.active {
      color: $green;

      .icon {
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }
    }

    &.disabled {
      opacity: 0.3;

      &:hover {
        border: 0;
      }
    }

    .icon_holder {
      left: 50%;
      width: 100%;
      height: 35px;
      margin-bottom: 10px;
      position: relative;
      transform: translate(-50%, 0);

      .icon {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    span {
      // padding: 0 5px;
    }
  }

  @include phone {
    .type.section {
      text-align: center;
      position: relative;
      margin-bottom: 15px;
      .label {
        text-align: left;
      }
      button {
        width: 100%;
        padding-left: 50px;
        margin: 5px 0;
        .icon_holder {
          float: left;
          width: 50px;
          height: 30px;
          // background: red;
          position: absolute;
          left: 0;
          transform: none;
        }
      }
      span {
        float: left;
        line-height: 30px;
        font-size: $medium_font_size;
      }
    }
  }
}
