// Variables
@import './utilities/variables';

.three_columns {
    // padding-bottom: 15%;
    width: 50%;
    left: 25%;
    margin-top: 50px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    transition: width 0.5s 0.5s ease, left 0.5s 0.5s ease;

    @include phone {
        display: block;
        left: 0;
        width: 100%;
    }
    @include tablet {
        left: 10%;
        width: 80%;
    }

    .column {
        padding: 35px 15px 50px 15px;
        text-align: center;
        position: relative;
        width: 33%;
        transition: padding 0.5s 0.5s ease;
        &.no_button {
            padding-bottom: 15px;
        }
        @include phone {
            width: 100%;
            box-sizing: border-box;
            margin: 30px 0;
            &.no_button {
                padding-bottom: 0;
            }
        }

        .button {
            position: absolute;
            bottom: 0px;
            left: 50%;
            max-width: 80%;
            transform: translateX(-50%);
            white-space: nowrap;
            overflow: hidden;
            @include phone {
                background: $primary;
                color: $white;
            }
        }

        .icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
